import React from 'react';
import { EmailChangeDateType } from 'js/components/specific/top/IdentificationNotice/type';

export type Props = EmailChangeDateType;

export const IdentificationNoticeStep3Presenter: React.FC<Props> = ({
  reception_at,
  start_at,
  end_at,
}) => (
  <div className="identification-step c_emphasis-info">
    <p className="c_emphasis-info__heading">メールアドレスリセット審査完了</p>
    <ul className="identification-step__list">
      <li className="identification-step__item">
        <span className="identification-step__number">1</span>
        <p className="identification-step__label">申請</p>
      </li>
      <li className="identification-step__item">
        <span className="identification-step__number">2</span>
        <p className="identification-step__label">審査(7日間)</p>
      </li>
      <li className="identification-step__item is_active">
        <span className="identification-step__number">3</span>
        <p className="identification-step__label">変更受付</p>
      </li>
      <li className="identification-step__item">
        <span className="identification-step__number">4</span>
        <p className="identification-step__label">本人認証</p>
      </li>
    </ul>
    <p className="identification-step__description">
      <span className="identification-step__sub-heading">
        メールアドレス変更可能期間
      </span>
      <br />
      <span className="identification-step__data">
        {start_at}&nbsp;&#xFF5E;&nbsp;{end_at}
      </span>
      <br />
      （申請日：{reception_at}）
    </p>
    <p className="c_emphasis-info__message">
      メールアドレス変更可能期間中に、メールアドレス変更をしてください。
    </p>
    <a href="/mypage/email/" className="c_button">
      メールアドレスの変更
    </a>
    <ul className="c_emphasis-info__list">
      <li className="c_emphasis-info__item">
        この審査に心当たりがない場合は早急に
        <a href="/support/">こちら</a>
        よりお問い合わせください。
      </li>
    </ul>
  </div>
);
