import { useRequest } from 'js/hooks/useRequestWithDebugParams';
import {
  Code,
  TimeKeeperResponse,
  TimeKeeperResult,
} from 'js/hooks/useTimeKeeper/type';

/**
 * next.jsのページ以外での使用は控えてください
 * @see js/hooks/useRequestWithDebugParams
 *
 * @param code
 */
export const useTimeKeeper = ({ code }: Code): TimeKeeperResult => {
  const { data } = useRequest<TimeKeeperResponse>(
    `/api/time_keeper/?code=${code}`,
  );

  return data
    ? {
        within: data.within,
        outOfRange: data.outOfRange,
        isNotYet: data.isNotYet,
        isPast: data.isPast,
      }
    : {
        within: undefined,
        outOfRange: undefined,
        isNotYet: undefined,
        isPast: undefined,
      };
};
